import React from 'react';

const importAll = (r) => r.keys().map(r);

const Winter = () => {
  // Dynamically import all images from the 'climbing' folder
  const images = importAll(require.context('../assets/images/winter', false, /\.(png|jpe?g|svg)$/));

  // Sort images by filename
  const sortedImages = images.sort((a, b) => {
    const nameA = a.split('/').pop().toLowerCase();
    const nameB = b.split('/').pop().toLowerCase();
    return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
  });

  return (
    <div>
      <h1 className="galleryTitle">Winter</h1>
      <p className="galleryIntro">
        Winter brings a whole new perspective to the big mountains. If you are going to live here, you had better learn to love the cold and the unique adventures this incredible season can unlock. Here are a few of my favorite winter pursuits from the past two decades.
      </p>
      <div className="image-grid">
        {sortedImages.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img src={image} alt={`Winter ${index + 1}`} className="image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Winter;
