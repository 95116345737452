import React from 'react';
import { Link } from 'react-router-dom';

import Insta from '../assets/images/insta.png';
import Logo from '../assets/images/logo.png';

const Sidebar = () => (
  <div className="sidebar">
    <Link to="/" className="sidebarTitle"><h2><img src={Logo} alt="Climbing" style={{ maxWidth: '35px', height: 'auto', marginRight: '10px', marginBottom: '-10px' }} />Jonny Coe</h2></Link>
    <p className="sidebarTagline">Visual stories of incredible people doing great things in wild places. Climbing & adventure commercial and editorial photography</p>
    <ul className="sidebarList">
      <li><Link to="/climbing">→ Stone</Link></li>
      <li><Link to="/people">→ People</Link></li>
      <li><Link to="/places">→ Places</Link></li>
      <li><Link to="/winter">→ Winter</Link></li>
    </ul>
    <ul className="sidebarList second">
      <li><Link to="/about">About</Link></li>
      <li><Link to="https://alpinejournals.com" target="_blank">Blog</Link></li>
      <li><Link to="/contact">Contact</Link></li>
    </ul>
    <ul className="sidebarList second">
      <li><Link to="https://instagram.com/alpinejonny" target="_blank"><img src={Insta} alt="Climbing" style={{ maxWidth: '18px', height: 'auto' }} /></Link></li>
    </ul>
  </div>
);

export default Sidebar;
