import React from 'react';
import climbingImage from '../assets/images/about/climbing-1020.jpg';

const About = () => {
  return (
    <div>
      <h1 className='galleryTitle'>About Jonny</h1>
      <img src={climbingImage} alt="Climbing" style={{ width: 'auto', maxWidth: '800px', height: 'auto', marginLeft: '20px' }} />
      <p className='galleryIntro'>Jonny is a photographer, cinematographer, and certified drone pilot, driven by a passion for capturing images that tell compelling stories. His work blends a photojournalistic approach with a deep love of the vertical, and the bonds we build pushing ourselves to our limits. Jonny's focus is primarily on photography, with a strong eye for detail and a commitment to impactful visual storytelling.</p>
      <p className='galleryIntro'>Originally from Rossland, BC, Jonny's connection with the mountains began at an early age and only deepened as he pursued climbing and skiing across some of North America's most challenging terrain. Now based in Canmore, Alberta, Jonny's climbing achievements include sport routes up to 5.13d, traditional climbs up to 5.12, and numerous big wall ascents on both limestone and granite. Alongside his climbing prowess, Jonny is an experienced steep skier, having skied many major lines throughout the Canadian Rockies.</p>
      <p className='galleryIntro'>Jonny's work is characterized by a dedication to spending time in the field, building genuine relationships, and capturing the essence of the environments and people he documents. His photography is marked by authenticity and a deep connection to the landscapes he explores, whether through the lens of a camera or on the sharp end of a rope.</p>
      <p className='galleryIntro'>Beyond photo and film, Jonny is a tenured Director of Engineering for a AWS cloud and technology consultant where he supports initiatives to help organizations of all sizes solve challenging problems.</p>
    </div>
  );
};

export default About;
