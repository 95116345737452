import React, { useState, useEffect } from 'react';

const importAll = (r) => r.keys().map(r);

// Shuffle function to randomize the images array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const Home = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Dynamically import all images from the 'home' folder
  const images = importAll(
    require.context('../assets/images/home', false, /\.(png|jpe?g|svg)$/)
  );

  // Shuffle images array
  const shuffledImages = shuffleArray(images);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Clean up the selectedImage after fade-out transition
  useEffect(() => {
    let timer;
    if (!isModalOpen && selectedImage) {
      timer = setTimeout(() => {
        setSelectedImage(null);
      }, 500); // Duration matches the CSS transition duration
    }
    return () => clearTimeout(timer);
  }, [isModalOpen, selectedImage]);

  return (
    <div>
      <h1 className="galleryTitle">
        Climbing & Adventure Photography in the Canadian Rockies and beyond
      </h1>
      <p className="galleryIntro">
        {/* You can add an introduction text here */}
      </p>
      <div className="image-grid">
        {shuffledImages.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img
              src={image}
              alt={`Jonny Coe Portfolio ${index + 1}`}
              className="image"
              onClick={() => handleImageClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div
          className={`modal ${isModalOpen ? 'show' : ''}`}
          onClick={closeModal}
        >
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <img
            className="modal-content"
            src={selectedImage}
            alt="Full Size"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
