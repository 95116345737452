import React, { useState, useEffect } from 'react';

const importAll = (r) => r.keys().map(r);

const Climbing = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Dynamically import all images from the 'climbing' folder
  const images = importAll(
    require.context('../assets/images/climbing', false, /\.(png|jpe?g|svg)$/)
  );

  // Sort images by filename
  const sortedImages = images.sort((a, b) => {
    const nameA = a.split('/').pop().toLowerCase();
    const nameB = b.split('/').pop().toLowerCase();
    return nameA.localeCompare(nameB, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Clean up the selectedImage after fade-out transition
  useEffect(() => {
    let timer;
    if (!isModalOpen && selectedImage) {
      timer = setTimeout(() => {
        setSelectedImage(null);
      }, 500); // Duration matches the CSS transition duration
    }
    return () => clearTimeout(timer);
  }, [isModalOpen, selectedImage]);

  return (
    <div>
      <h1 className="galleryTitle">Climbing</h1>
      <p className="galleryIntro">
        Rock & Alpine Climbing is where it all begins and ends for me. The
        freedom of movement over stone, and the doors unlocked by developing
        the capabilities and vision are endless. Here is a collection of work
        developed over the past 14+ years of sport, trad, alpine and
        mountaineering pursuits.
      </p>
      <div className="image-grid">
        {sortedImages.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img
              src={image}
              alt={`Climbing ${index + 1}`}
              className="image"
              onClick={() => handleImageClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div
          className={`modal ${isModalOpen ? 'show' : ''}`}
          onClick={closeModal}
        >
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <img
            className="modal-content"
            src={selectedImage}
            alt="Full Size"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default Climbing;
