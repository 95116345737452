import React from 'react';

const importAll = (r) => r.keys().map(r);

const Places = () => {
  // Dynamically import all images from the 'climbing' folder
  const images = importAll(require.context('../assets/images/places', false, /\.(png|jpe?g|svg)$/));

  // Sort images by filename
  const sortedImages = images.sort((a, b) => {
    const nameA = a.split('/').pop().toLowerCase();
    const nameB = b.split('/').pop().toLowerCase();
    return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
  });

  return (
    <div>
      <h1 className="galleryTitle">Places</h1>
      <p className="galleryIntro">
      The places we go shape who we are and the stories we tell. These are some of my favorite places that I have been fortunate to see and create images from.
      </p>
      <div className="image-grid">
        {sortedImages.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img src={image} alt={`People ${index + 1}`} className="image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Places;
