import React, { useState } from 'react';

function App() {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    caption: '',
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.file) {
      alert('Please select a file to upload.');
      return;
    }

    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('description', formData.description);
    formDataObj.append('caption', formData.caption);
    formDataObj.append('file', formData.file);

    fetch('http://34.233.166.55/create', {
      method: 'POST',
      body: formDataObj,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        alert('Image uploaded successfully');
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div>
      <h1>Upload Image</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          placeholder="Title"
          onChange={handleChange}
          required
        />
        <br />
        <input
          type="text"
          name="description"
          placeholder="Description"
          onChange={handleChange}
          required
        />
        <br />
        <input
          type="text"
          name="caption"
          placeholder="Caption"
          onChange={handleChange}
          required
        />
        <br />
        <input type="file" name="file" onChange={handleFileChange} required />
        <br />
        <button type="submit">Upload Image</button>
      </form>
    </div>
  );
}

export default App;
