import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Gallery from './components/Gallery';
import Climbing from './pages/Climbing';
import People from './pages/People';
import Places from './pages/Places';
import About from './pages/About';
import Contact from './pages/Contact';
import Winter from './pages/Winter';
import Home from './pages/Home';
import Upload from './pages/Upload';

const App = () => {
  return (
    <Router>
      <div>
        <Sidebar />
        <Gallery>
          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/climbing" element={<Climbing />} />
            <Route path="/people" element={<People />} />
            <Route path="/places" element={<Places />} />
            <Route path="/winter" element={<Winter />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/upload" element={<Upload />} />
          </Routes>
        </Gallery>
      </div>
    </Router>
  );
}

export default App;
