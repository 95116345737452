import React from 'react';

const importAll = (r) => r.keys().map(r);

const People = () => {
  // Dynamically import all images from the 'climbing' folder
  const images = importAll(require.context('../assets/images/people', false, /\.(png|jpe?g|svg)$/));

  // Sort images by filename
  const sortedImages = images.sort((a, b) => {
    const nameA = a.split('/').pop().toLowerCase();
    const nameB = b.split('/').pop().toLowerCase();
    return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
  });

  return (
    <div>
      <h1 className="galleryTitle">People</h1>
      <p className="galleryIntro">
      Stories are crafted from our experiences. The connection we find with our environment, and the moments we share together. These are the people in my life, in the places they are the most happy.
      </p>
      <div className="image-grid">
        {sortedImages.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img src={image} alt={`People ${index + 1}`} className="image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default People;
